import { create } from "zustand";
import { isMobile } from "../utils/common";
import { MOBILE_NATIVE_TEST_ENABLED } from "../config";

export interface FormsState {
  formLoading: boolean;
  setFormLoading: (value: boolean) => void;
}

export const useFormsStore = create<FormsState>((set) => ({
  formLoading: false,
  setFormLoading: (formLoading) => set({ formLoading }),
}));

export interface SplashState {
  showFakeSplashScreen: boolean;
  setShowFakeSplashScreen: (value: boolean) => void;
}

export const useSplashScreenStore = create<SplashState>((set) => ({
  showFakeSplashScreen: isMobile(MOBILE_NATIVE_TEST_ENABLED),
  setShowFakeSplashScreen: (showFakeSplashScreen) =>
    set({ showFakeSplashScreen }),
}));
