import cx from "classnames";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import s from "./ForgotPassword.module.scss";
import {
  IonLabel,
  IonPage,
  IonToast,
  useIonRouter,
  useIonViewDidLeave,
} from "@ionic/react";
import {
  WLFormInput,
  WLFormInputProps,
} from "../../../Common/components/WLForms/WLFormInput/WLFormInput";
import { FormProvider, useForm } from "react-hook-form";
import { WLButton } from "../../../Common/components/WLButton/WLButton";
import { sendResetPassword } from "../../services/user";
import { WLContent } from "../../../Common/components/WLContent/WLContent";
import { SuccessCheckEmail } from "./SuccessCheckEmail/SuccessCheckEmail";
import { getErrorMsg } from "../../../Common/utils/common";
import { bugNotif } from "../../api/bugsnag";
import { useWLLoaderStore } from "../../../Common/components/WLLoader/store";
import { WLCommonBasicHeader } from "../../../Common/components/WLCommonBasicHeader/WLCommonBasicHeader";
import { WEB_SIGN_UP_OLD } from "../../constants/routes";

interface ForgotPassword {
  email: string;
}
export const ForgotPassword = () => {
  const useFormMethods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit, setValue, clearErrors, getValues } = useFormMethods;

  const emailField: WLFormInputProps = {
    label: "Email Address",
    name: "email",
    type: "email",
    placeholder: "Email Address",
    rules: {
      required: "This is required.",
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: "Invalid email address, please input valid email address",
      },
    },
  };
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccessScreen, setSuccessScreen] = useState(false);

  const setLoading = useWLLoaderStore((state) => state.setLoading);

  const {
    mutate: onSendResetPassword,
    isError,
    isSuccess,
  } = useMutation({
    mutationFn: sendResetPassword,
    onSuccess: (response) => {
      setLoading(false);
      console.log("Success - response -- ", response);
      setSuccessMsg("Email sent!");
      setSuccessScreen(true);
    },
    onError: (err: any) => {
      setLoading(false);
      setErrorMsg(getErrorMsg(err));
      setSuccessScreen(false);
      const error = err as any;
      const errorMsg = getErrorMsg(error);
      bugNotif("<ForgotPassword - sendResetPassword>", errorMsg);
    },
  });

  const forgotPassword = (data: any) => {
    const forgotPasswordData = data as ForgotPassword;
    setLoading(true);
    onSendResetPassword(forgotPasswordData.email);
  };

  const cleanup = () => {
    setValue(emailField.name, "");
    setSuccessScreen(false);
    clearErrors();
    setLoading(false);
  };
  useIonViewDidLeave(() => {
    cleanup();
  });
  const router = useIonRouter();
  return (
    <IonPage className={s.forgotPasswordPage}>
      <WLCommonBasicHeader
        headerClassName={s.header}
        overrideBackFunction={() => {
          router.push(WEB_SIGN_UP_OLD, "back", "pop");
        }}
      />
      <WLContent className={cx(s.content, "no-border-radius")}>
        {showSuccessScreen ? (
          <SuccessCheckEmail
            handleResendEmail={() => {
              onSendResetPassword(getValues(emailField.name));
            }}
          />
        ) : (
          <>
            <IonLabel className="wl-h7">FORGOT PASSWORD?</IonLabel>
            <IonLabel className="wl-body-6 light ion-margin-top">
              Login to your Winners Locker account with your email address and
              password.
            </IonLabel>
            <FormProvider {...useFormMethods}>
              <form className={s.form} onSubmit={handleSubmit(forgotPassword)}>
                <div className={s.formContainer}>
                  <div className={s.inputsContainer}>
                    <WLFormInput {...emailField} />
                  </div>
                  <div className={s.footer}>
                    <WLButton
                      type="submit"
                      className={cx(s.button, "wl-body-6 red-submit-button")}
                      isFormComponent
                    >
                      Send Email
                    </WLButton>
                  </div>
                </div>
              </form>
            </FormProvider>
          </>
        )}

        <IonToast
          isOpen={isError}
          color="danger"
          message={errorMsg}
          duration={5000}
        ></IonToast>
        <IonToast
          isOpen={isSuccess}
          color="success"
          message={successMsg}
          duration={3000}
        ></IonToast>
      </WLContent>
    </IonPage>
  );
};
