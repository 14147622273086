import { PriceDiscoveryVersion } from "../../Common/models/subscriptions";
import { UserWebCreate, UserWebCreate2 } from "../../Common/models/user";
import { doGet, doPost, encodeQueryData } from "../../Common/utils/https";
import { getRequestHeaders } from "../../Common/utils/https";
import { cloudFunctionsUrl } from "../firebase";
const baseUrl = cloudFunctionsUrl;

export const getStripeSubscriptions = async (
  alternativePriceVersion?: PriceDiscoveryVersion
) => {
  const url =
    alternativePriceVersion === undefined
      ? `${baseUrl}stripe/getSubscriptions`
      : `${baseUrl}stripe/getSubscriptions?${encodeQueryData({
          alternativePriceVersion,
        })}`;
  const headers = await getRequestHeaders();
  return doGet(url, headers);
};

export const createStripeCustomer = async (user: UserWebCreate) => {
  const url = `${baseUrl}stripe/createCustomer`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, user);
};

export const validateStripePromo = async ({
  promoCode,
}: {
  promoCode: string;
}) => {
  const url = `${baseUrl}stripe/validatePromo?${encodeQueryData({
    promoCode,
  })}`;

  const headers = await getRequestHeaders();
  return doGet(url, headers);
};

export const createStripeSubscriptionV2 = async (
  customerId: string,
  priceId: string,
  couponId = "",
  promoCode = ""
) => {
  const url = `${baseUrl}stripe/createSubscriptionV2`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, {
    customerId,
    priceId,
    couponId,
    promoCode,
  });
};

export const onMiniGameTriggered = async (
  subscriptionId: string,
  triggeredMiniGame: boolean
) => {
  const url = `${baseUrl}stripe/onMiniGameTriggered`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, {
    subscriptionId,
    triggeredMiniGame,
  });
};

interface CreateStripeSubscriptionSinglePage extends UserWebCreate2 {
  priceId: string;
  couponId: string;
  promoCode: string;
}
export const createSubscriptionSinglePage = async (
  params: CreateStripeSubscriptionSinglePage
) => {
  const url = `${baseUrl}stripe/createSubscriptionSinglePage`;
  const headers = await getRequestHeaders();
  return doPost(url, headers, {
    ...params,
  });
};
