import cx from "classnames";
import { memo, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import s from "./StripePaymentForm.module.scss";
import { stripeKey } from "../../../firebase";
import StripePaymentForm from "./StripePaymentForm";
import { IonLabel } from "@ionic/react";
import { WLSkeleton } from "../../../../Common/components/WLSkeleton/WLSkeleton";
import useWebSignupStore from "../store";
import { isNull } from "lodash";
import { getStripePriceValueWithPromo } from "../../../services/promo";

const stripePromise = loadStripe(stripeKey);

const StripeForm = () => {
  const { selectedSubscription, validStripeCoupon } = useWebSignupStore();

  const amount = useMemo(() => {
    if (
      selectedSubscription === undefined ||
      validStripeCoupon === undefined ||
      isNull(selectedSubscription)
    ) {
      return;
    }

    return !isNull(validStripeCoupon)
      ? Math.round(
          getStripePriceValueWithPromo(
            selectedSubscription.price,
            validStripeCoupon
          ) * 100
        ) // Convert to the smallest currency unit
      : Math.round(selectedSubscription.price * 100);
  }, [selectedSubscription, validStripeCoupon]);

  return (
    <div className={s.container}>
      {amount !== undefined ? (
        <>
          <IonLabel className={cx(s.title, "wl-medium primary normal")}>
            PAYMENT
          </IonLabel>

          <Elements
            stripe={stripePromise}
            options={{
              locale: "en-AU",
              amount,
              currency: "aud",
              setup_future_usage: "off_session",
              mode: "subscription",
            }}
          >
            <StripePaymentForm />
          </Elements>
        </>
      ) : (
        <WLSkeleton width="100%" height="300px" />
      )}
    </div>
  );
};

export default memo(StripeForm);
