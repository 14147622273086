import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";

import { useIonRouter } from "@ionic/react";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { stripeKey } from "../../firebase";
import { HARDCODED_SUBS_PRICING } from "../../../Common/config";
import {
  completePurchaseSubscriptionKlaviyoEvent,
  sendFBTracking,
  sendSnapTracking,
  sendTTQCompletedPurchaseEvent,
  sendgtag,
  sendttqTracking,
  trackWebSignUpStep,
} from "../../services/user";
import { getErrorMsg, useEffectOnlyOnce } from "../../../Common/utils/common";
import { bugNotif } from "../../api/bugsnag";
import { WEB_SIGN_UP_OLD } from "../../constants/routes";
import useWebPaymentLandingStore from "./store";

export const useWebPaymentLandingProvider = (redirectToApp?: boolean) => {
  const router = useIonRouter();

  const {
    setExistingUser,
    setPowerUp,
    setError,
    setPaymentMessage,
    setPaymentError,
    setSignUpName,
    setPid,
    setRedirectToApp,
  } = useWebPaymentLandingStore();

  // const [existingUser, setExistingUser] = useState(false);
  // const [powerUp, setPowerUp] = useState(false);
  // const [error, setError] = useState("");
  // const [paymentMessage, setPaymentMessage] = useState("");
  // const [paymentError, setPaymentError] = useState("");

  useEffectOnlyOnce(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    const userId = new URLSearchParams(window.location.search).get("userId");
    const existing = new URLSearchParams(window.location.search).get(
      "existing"
    );
    const powerUp = new URLSearchParams(window.location.search).get("powerUp");
    const pid = new URLSearchParams(window.location.search).get("pid");
    const signedUpFullName = new URLSearchParams(window.location.search).get(
      "signedUpFullName"
    );

    console.log({ clientSecret });
    setPowerUp(!isEmpty(powerUp));

    const userExisting = !isEmpty(existing);
    if (userExisting) {
      setExistingUser(true);
    }
    if (signedUpFullName) {
      setSignUpName(signedUpFullName.split("_").join(" "));
    }

    setPid(pid || "");

    if (
      !isEmpty(clientSecret) &&
      !isEmpty(userId) &&
      !isNull(clientSecret) &&
      !isNull(userId)
    ) {
      checkPaymentStatus(
        clientSecret,
        userId,
        !isEmpty(powerUp),
        pid || "",
        userExisting
      );
    } else if (!redirectToApp) {
      router.push(WEB_SIGN_UP_OLD, "back", "pop");
    } else {
      setRedirectToApp(redirectToApp);
      if (redirectToApp) {
        setPaymentMessage("YOU ALREADY HAVE AN ACCOUNT WITH US");
      }
    }
  });

  let tries = 0;
  const checkPaymentStatus = async (
    intentSecret: string,
    userId: string,
    isPowerUp = false,
    pid = "",
    existing = false
  ) => {
    const stripe = await loadStripe(stripeKey);
    if (!isNull(stripe)) {
      stripe
        .retrievePaymentIntent(intentSecret)
        .then(async ({ paymentIntent }) => {
          console.log("got payment intent --", paymentIntent);
          if (paymentIntent !== undefined) {
            switch (paymentIntent.status) {
              case "succeeded":
                setPaymentMessage(
                  !isPowerUp
                    ? !existing
                      ? "PROFILE 80% COMPLETE"
                      : "MEMBERSHIP UNLOCKED!"
                    : "POWER UP UNLOCKED!"
                );
                break;

              default:
                setPaymentError("Something went wrong.");
                break; // if something went wrong, delete stripe customer and then show QR
            }

            if (
              paymentIntent.status === "succeeded" &&
              !existing &&
              !isEmpty(pid)
            ) {
              const amount = paymentIntent.amount / 100;
              sendgtag(paymentIntent.id, amount);
              const event_id = await sendttqTracking(userId, pid, amount);
              sendSnapTracking(
                userId,
                isPowerUp ? "Power Up" : "Membership",
                pid,
                amount
              );
              sendFBTracking(
                isPowerUp ? "Power Up" : "Membership",
                pid,
                amount
              );
              const tiktokSource = new URLSearchParams(
                window.location.search
              ).get("ttclid");
              sendTTQCompletedPurchaseEvent(
                userId,
                amount,
                pid,
                tiktokSource,
                event_id
              );

              const emailAddress = new URLSearchParams(
                window.location.search
              ).get("emailAddress");

              const signUpVersion = new URLSearchParams(
                window.location.search
              ).get("signUpVersion");

              await trackWebSignUpStep(
                emailAddress || "",
                "completeSignUp",
                parseInt(signUpVersion || "4") as 4 | 6
              );

              completePurchaseSubscriptionKlaviyoEvent(pid, amount);
            }
          } else {
            setPaymentError("Something went wrong. Please try again.");
          }
        })
        .catch((error) => {
          setPaymentMessage(
            !isPowerUp
              ? !existing
                ? "PROFILE 80% COMPLETE"
                : "MEMBERSHIP UNLOCKED!"
              : "POWER UP UNLOCKED!"
          );

          bugNotif("stripe.retrievePaymentIntent", getErrorMsg(error));
        });
    } else {
      setTimeout(async () => {
        tries += 1;
        if (tries < 3) {
          checkPaymentStatus(intentSecret, userId, isPowerUp, pid, existing);
        } else {
          setError("Something went wrong. Please contact us for support.");
        }
      }, 500);
    }
  };

  return;
};
